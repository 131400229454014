import React, { useState } from 'react';
import resume from '../../assets/resume.pdf';
const Resume = () => {
  if (typeof window !== 'undefined') {
    window.location.pathname = `${resume}`;
  }
  return null;
};

export default Resume;
